import { colors } from '@bamboohr/fabric/dist/definitions/json/colors.json';

export default {
	paragraph: (bottomMargin) => {
		return {
			color: colors.gray7,
			marginBottom: bottomMargin ? `${ bottomMargin }px` : '24px',
		};
	},
};
