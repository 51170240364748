import React, { Fragment } from 'react';
import { Lock60x72, Wrench72x72 } from '@bamboohr/grim';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';
import LockOutInterstitial from '../LockOutInterstitial';
import sharedStyles from '../../shared/styles';
import styles from './styles';

const FraudLockOut = ({ isPotentialFraud }) => {
	const classNames = styles();
	const emailAddress = isPotentialFraud ? 'success@bamboohr.com' : 'support@bamboohr.com';

	return (
		<LockOutInterstitial>
			<Fragment>
				<div className={ classNames.icon }>
					{ isPotentialFraud ? <Lock60x72 /> : <Wrench72x72 /> }
				</div>
				<h4>{ $.__('We\'re working on it.') }</h4>
				<p style={ { ...sharedStyles.paragraph(16) } }>
					<Message
						link={ ExternalLink(`mailto:${ emailAddress }`) }
						text={ $.__('Sorry, this site is temporarily unavailable. Questions? [Email us].') }
					/>
				</p>
			</Fragment>
		</LockOutInterstitial>
	);
};

FraudLockOut.defaultProps = {
	isPotentialFraud: false,
};

export default FraudLockOut;
