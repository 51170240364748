import { makeStyles } from '@bamboohr/fabric';

export default makeStyles(({ palette }) => {
	return {
		icon: {
			fill: palette.primary.main,
			marginTop: '28px',
		},
	};
});
